//控制台
import myhttp from './http'

//听证数量统计
export function getbackhringinfo(id) {
    return myhttp({
        url: `/backhome/backhringinfo`,
        method: "POST",
        params:{id}
    });
}
//用户统计
export function getuserstatistical(id) {
    return myhttp({
        url: `/backhome/userstatistical`,
        method: "POST",
        params:{id}
    });
}
//用户性别统计
export function getSexCount(id) {
    return myhttp({
        url: `/backhome/userSexCount`,
        method: "POST",
        params:{id}
    });
}
//专业领域统计
export function getuserPerCount(id) {
    return myhttp({
        url: `/backhome/userPerCount`,
        method: "POST",
        params:{id}
    });
}
//折线图
export function getlineChart() {
    return myhttp({
        url: `/Console/getlineChart`,
        method: "GET",
    });
}

//发送状态
export function getSendStatus(){
    return myhttp({
        url: `/sms/selectSmsInfo`,
        method: "GET",
    })
}

//改变短信发送状态
export function updateSendStatus(sms){
    return myhttp({
        url: `/sms/updateSmsInfo`,
        method: "POST",
        data:sms
    })
}
